@tailwind base;
@tailwind components;
@tailwind utilities;

.react-datepicker__input-container {
  position: inherit !important;
}

.hero-bg-dark-gradient {
  background: linear-gradient(180deg, #001b51 0%, rgba(0, 23, 68, 0.27) 54.48%);
  mix-blend-mode: multiply;
}

.hero-bg-dark-blue-gradient {
  background: linear-gradient(183.37deg, #080e3c 2.78%, #1e3e7e 124.63%);
  mix-blend-mode: normal;
}

.hero-blue-gradient {
  background: linear-gradient(0deg, #0057ff -0.78%, #0d00a5 101.13%);
}

.drive-around-card-shadow {
  filter: drop-shadow(0px 5.46369px 27.3184px rgba(54, 92, 196, 0.1));
}

.drive-around-card-top-border {
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: #e5eaf6;
}

.destinations-near-card-gradient {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.027) 0%,
    rgba(0, 0, 0, 0.525) 60.94%
  );
  background-blend-mode: multiply;
}

.newsletter-gradient {
  background: linear-gradient(89.27deg, #004de3 4.08%, #0b0aad 96.66%);
}

.search-btn-yellow-gradient {
  background: linear-gradient(115deg, #ffb800 7%, #ffd234 80%);
}

.search-btn-gradient {
  background: linear-gradient(92.51deg, #004de3 -2.13%, #0d00a5 112.96%);
}

.search-box-shadow {
  box-shadow: 0px 4px 20px rgba(54, 92, 196, 0.1);
}

.best-deals-text-shadow {
  text-shadow: 8.87199px 3.90367px 11.5336px #0b008c;
}

.best-deals-yellow-gradient {
  background: linear-gradient(256.1deg, #ffd600 21.74%, #ff9900 46.33%);
}

.best-deals-yellow-2-gradient {
  background: linear-gradient(107.59deg, #ffd600 36.98%, #ff9900 83.89%);
}

.seconday-newsletter-btn-gradient {
  background: linear-gradient(92.51deg, #004de3 -2.13%, #0d00a5 112.96%);
}

.border-shadow {
  box-shadow: 0px 5.46369px 27.3184px rgba(54, 92, 196, 0.1);
}

.navbar-gradient {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.73) 0%,
    rgba(0, 0, 0, 0.459) 100%
  );
}

.language-btn-gradient {
  background: rgba(39, 70, 125, 0.38);
}

.carbg {
  background-size: cover;
  background: linear-gradient(
      151.37deg,
      rgba(0, 0, 0, 0.2) 7.3%,
      rgba(0, 0, 0, 0) 30.01%
    ),
    url("/homepage-car.svg");
  background-blend-mode: multiply, normal;
}

.medalbg {
  background: linear-gradient(
    180deg,
    #5000d1 0%,
    #5a00ed 38.54%,
    #6100ff 66.67%,
    #4900c1 100%
  );
}

.popupbtn {
  background: linear-gradient(107.15deg, #3f00a7 6.56%, #3b009b 80.46%);
}

.search-btn-gradient {
  background: linear-gradient(89.27deg, #004de3 4.08%, #0b0aad 96.66%);
}

.search-box-shadow {
  box-shadow: 0px 4px 20px rgba(54, 92, 196, 0.1);
}

.eq-height {
  align-self: stretch;
}

.navbar-phone-gradient {
  background: linear-gradient(91.96deg, #0057ff -0.78%, #0d00a5 101.13%);
  border: 1px solid #0d2fff;
}

.great-deals-decoration {
  background: linear-gradient(91.73deg, #0530cc 46.78%, #081dbc 69.39%);
}

.ad-phone-shadow {
  box-shadow: 0px 4px 18px rgba(88, 88, 88, 0.25);
}

.height-transition {
  max-height: 0;
  overflow: hidden;
  transition-property: max-height;
  transition-duration: 0.6s;
}

.serves-border {
  @media screen and (min-width: 1024px) {
    border-radius: 12px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23D8DFEEFF' stroke-width='4' stroke-dasharray='3%2c 10' stroke-dashoffset='27' stroke-linecap='square'/%3e%3c/svg%3e");
  }
}

.car-card-shadow {
  box-shadow: 0px 5.46369px 27.31844px 0px rgba(54, 92, 195, 0.1);

  @media screen and (max-width: 1024px) {
    box-shadow: none;
  }
}

.car-card-divider-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E5EAF6FF' stroke-width='2' stroke-dasharray='4%2c 8' stroke-dashoffset='23' stroke-linecap='round'/%3e%3c/svg%3e");
}

.ad-marker {
  background-image: url("/ad-marker.svg");
  width: 24px;
  height: 16px;
  background-size: cover;
}

.ad-offer-anim-gradient {
  background: linear-gradient(200deg, #580300, #ff0037, #580300);
  background-size: 200% 200%;
  animation: gradientAnimation 3s none infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

h2 {
  @apply lg:max-w-[60vw];
}

table,
th,
td {
  @apply border border-gray-300;
}

.text-shadow {
  text-shadow: 1px 1px 2px #fff;
}
